<template>
  <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
  >
    <v-card-title>Вход</v-card-title>
    <v-card-text>
    <v-form
        v-on:submit="login"
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-text-field
          v-model="username"
          :rules="emailRules"
          label="Email"
          validate-on-blur
          required
      ></v-text-field>
      <v-text-field
          type="password"
          v-model="password"
          :rules="passwordRules"
          label="Пароль"
          required
      ></v-text-field>
    </v-form>

      <v-alert v-if="error"
          dense
          outlined
          type="error"
      >
        {{ errorRu }}
      </v-alert>

    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="login"
      >
        Войти
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserAuth',
  data: () => ({
    valid: false,
    loading: false,
    error: null,
    username: '',
    password: '',
    emailRules: [
      v => !!v || 'Введите email',
      v => /.+@.+\..+/.test(v) || 'Некорректный email',
    ],
    passwordRules: [
      v => !!v || 'Введите пароль',
    ],
  }),
  computed: {
    errorRu() {
      const translate = {
        'Incorrect Email or password': 'Логин или пароль указаны неверно',
      };
      if (translate[this.error]) {
        return translate[this.error];
      }
      return this.error;
    }
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = null;
        try {

          const { client_id, redirect_uri, state} = this.$route.query;
          const params = new URLSearchParams()
          params.append('username', this.username);
          params.append('password', this.password);
          params.append('client_id', client_id);
          params.append('redirect_uri', redirect_uri);
          params.append('state', state);
          params.append('response_type', 'code');

          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }

          const response = await axios.post('/auth/user/authorize',params, config);

          const {data} = response;
          if (data && data.redirectUri) {
            const {redirectUri, authorizationCode} = data;
            let url = redirectUri;
            if (redirectUri.includes('?')){
              url += '&authorization_code=' + authorizationCode;
            } else {
              url += '?authorization_code=' + authorizationCode;
            }
            url += '&state=' + state;
            window.location.href = url;
          } else {
            this.error = 'Something went wrong';
          }
          this.loading = false;
        } catch (e) {
          const {response} = e;
          if (response) {
            this.error = response.data.message;
          } else {
            console.log(e);
          }
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
